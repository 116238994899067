<template>
  <div
    class="navbar-stick"
    :class="{ onScroll: !view.topOfPage }"
  >
    <div class="container">
      <header class="w-100 px-4 py-3">
        <nav class="d-flex justify-content-between align-items-center">
          <router-link to="/">
            <img class="logotype" src="@/assets/logo-aspi-full.svg" />
          </router-link>
          <div class="d-none d-lg-flex gap-4">
            <router-link to="/">
              <span
                class="ff-grandis text-uppercase"
                :class="{ 'tw-black': currentPath === '/' }"
                >Inicio</span
              >
            </router-link>
            <router-link to="/nosotros">
              <span
                class="ff-grandis text-uppercase"
                :class="{ 'tw-black': currentPath === '/nosotros' }"
                >Nosotros</span
              >
            </router-link>
            <router-link to="/asociados">
              <span
                class="ff-grandis text-uppercase"
                :class="{ 'tw-black': currentPath === '/asociados' }"
                >Asociados</span
              >
            </router-link>
            <router-link to="/afiliacion">
              <span
                class="ff-grandis text-uppercase"
                :class="{ 'tw-black': currentPath === '/afiliacion' }"
                >Afiliación</span
              >
            </router-link>
            <router-link to="/noticias">
              <span
                class="ff-grandis text-uppercase"
                :class="{ 'tw-black': currentPath === '/noticias' }"
                >Noticias</span
              >
            </router-link>
            <router-link to="/contacto">
              <span
                class="ff-grandis text-uppercase"
                :class="{ 'tw-black': currentPath === '/contacto' }"
                >Contáctanos</span
              >
            </router-link>
          </div>
          <div class="d-flex d-lg-none">
            <button class="btn button-menu" @click="toggleMenu">
              <i class="bi bi-list"></i>
            </button>
          </div>
        </nav>
      </header>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      view: {
        topOfPage: false,
      },
    };
  },
  beforeMount() {
    window.addEventListener("scroll", this.handleScroll);
  },
  beforeDestroy() {
    window.removeEventListener("scroll", this.handleScroll);
  },
  created() {
    this.handleScroll();
  },
  computed: {
    currentPath() {
      return this.$route.path;
    },
  },
  methods: {
    toggleMenu() {
      this.$emit("toggleMenu");
    },
    handleScroll() {
      if (window.pageYOffset > 200) {
        if (this.view.topOfPage) this.view.topOfPage = false;
      } else {
        if (!this.view.topOfPage) this.view.topOfPage = true;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.navbar-stick {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 800;
  background-color: var(--color-white);
  box-shadow: 0 8px 32px 0 rgba(138, 139, 146, 0.158);
  transform: translateY(-100%);
  transition: var(--transition-fast);
  opacity: 0;
  border-radius: 0 0 20px 20px;

  .logotype {
    height: 78px;
    transition: var(--transition-fast);
    &:hover {
      opacity: 0.7;
    }
    &:active {
      transform: scale(0.98);
    }
  }
  .button-menu {
    background: rgba($color: #000000, $alpha: 0.1);
    color: var(--color-text);
    font-size: var(--h3-font-size);
  }

  &.onScroll {
    opacity: 1;
    transform: translateY(0);
  }
}
</style>
