var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"container mt-5"},[_c('div',{staticClass:"organigrama-comp br-bigger"},[_c('div',{staticClass:"row"},[_vm._m(0),_c('div',{staticClass:"col col-12 d-none d-lg-block"},[_c('div',{staticClass:"d-flex flex-column align-items-center"},[_c('OrganiPerson',{attrs:{"data":_vm.president}}),_vm._m(1),_c('OrganiPerson',{attrs:{"data":_vm.vice}}),_vm._m(2)],1),_vm._m(3),_c('div',{staticClass:"sub-organization"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col col-3 d-flex flex-column align-items-center"},[_vm._m(4),_c('OrganiPerson',{attrs:{"data":_vm.honor}})],1),_c('div',{staticClass:"col col-3 d-flex flex-column align-items-center"},[_vm._m(5),_c('OrganiPerson',{attrs:{"data":_vm.rrpp}})],1),_c('div',{staticClass:"col col-3 d-flex flex-column align-items-center"},[_vm._m(6),_c('OrganiPerson',{attrs:{"data":_vm.secretary}})],1),_c('div',{staticClass:"col col-3 d-flex flex-column align-items-center"},[_vm._m(7),_c('OrganiPerson',{attrs:{"data":_vm.afiliation}})],1)])])]),_c('div',{staticClass:"col col-12 d-block d-lg-none"},[_c('div',{staticClass:"d-flex flex-column align-items-center"},[_c('OrganiPerson',{attrs:{"data":_vm.president}}),_vm._m(8),_c('OrganiPerson',{attrs:{"data":_vm.vice}}),_vm._m(9)],1),_c('div',{staticClass:"main-line-min"}),_c('div',{staticClass:"sub-organization"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col col-12 col-sm-6 d-flex flex-column align-items-center mb-4"},[_c('OrganiPerson',{attrs:{"data":_vm.honor}})],1),_c('div',{staticClass:"col col-12 col-sm-6 d-flex flex-column align-items-center mb-4"},[_c('OrganiPerson',{attrs:{"data":_vm.rrpp}})],1),_c('div',{staticClass:"col col-12 col-sm-6 d-flex flex-column align-items-center mb-4"},[_c('OrganiPerson',{attrs:{"data":_vm.secretary}})],1),_c('div',{staticClass:"col col-12 col-sm-6 d-flex flex-column align-items-center mb-4"},[_c('OrganiPerson',{attrs:{"data":_vm.afiliation}})],1)])])])])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col col-12 col-md-6 mb-5"},[_c('h2',{staticClass:"ff-grandis tw-medium ts-h1 mb-2"},[_vm._v("Organigrama")]),_c('p',{staticClass:"ts-normal tc-text-dark mb-0"},[_vm._v(" El organigrama de la asociación incluye un equipo directivo y comités especializados para lograr los objetivos de ASPI. ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"line-connect"},[_c('div',{staticClass:"line"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"line-connect"},[_c('div',{staticClass:"line"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"main-line"},[_c('div',{staticClass:"line"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"line-connect"},[_c('div',{staticClass:"line"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"line-connect"},[_c('div',{staticClass:"line"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"line-connect"},[_c('div',{staticClass:"line"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"line-connect"},[_c('div',{staticClass:"line"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"line-connect"},[_c('div',{staticClass:"line"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"line-connect"},[_c('div',{staticClass:"line"})])
}]

export { render, staticRenderFns }