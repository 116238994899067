<template>
  <div class="home-view">
    <HeaderHomeComp @toggleMenu="toggleMenu" />
    <QuienesSomosHomeComp />
    <ResultadosHomeComp />
    <CompromisoHomeComp />
    <OrganigramaComp />
    <IntegrantesHomeComp />
    <NoticiasHomeComp />
    <ContactoHomeComp />
  </div>
</template>

<script>
import HeaderHomeComp from '@/components/home_view/HeaderHomeComp.vue'
import QuienesSomosHomeComp from '@/components/home_view/QuienesSomosHomeComp.vue'
import ResultadosHomeComp from '@/components/home_view/ResultadosHomeComp.vue'
import CompromisoHomeComp from '@/components/home_view/CompromisoHomeComp.vue'
import OrganigramaComp from '@/components/home_view/OrganigramaComp.vue'
import BuscadorHomeComp from '@/components/home_view/BuscadorHomeComp.vue'
import IntegrantesHomeComp from '@/components/home_view/IntegrantesHomeComp.vue'
import NoticiasHomeComp from '@/components/home_view/NoticiasHomeComp.vue'
import ContactoHomeComp from '@/components/home_view/ContactoHomeComp.vue'
export default {
  name: 'HomeView',
  components: {
    HeaderHomeComp,
    QuienesSomosHomeComp,
    ResultadosHomeComp,
    CompromisoHomeComp,
    OrganigramaComp,
    BuscadorHomeComp,
    NoticiasHomeComp,
    ContactoHomeComp,
    IntegrantesHomeComp,
  },
  methods: {
    toggleMenu() {
      this.$emit("toggleMenu");
    }
  }
}
</script>

<style lang="scss" scoped>
.home-view {
  width: 100%;
  height: fit-content;
  padding-bottom: 4rem;
  overflow: hidden;
}
</style>
