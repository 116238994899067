<template>
  <div class="container mt-5">
    <div class="contacto-container br-bigger">
      <div class="row">
        <div class="col col-12 col-lg-6 position-relative">
          <h2 class="ff-grandis tw-medium ts-h1" data-aos="fade-right" data-aos-duration="800">
            Contacta a nuestro equipo de afiliación
          </h2>
          <div class="white-space"></div>
          <img
            data-aos="fade-right"
            data-aos-duration="800"
            data-aos-delay="100"
            class="contact-img br-big"
            src="@/assets/img/bcs_contact.jpg"
            alt=""
          />
        </div>
        <div class="col col-12 col-lg-6 p-4" data-aos="fade-left" data-aos-duration="1000">
          <p class="mb-3">/ LLENA EL SIGUIENTE FORMULARIO DE CONTACTO</p>
          <form @submit.prevent="sendEmail()">
            <input
              type="text"
              class="form-control"
              v-model="form.name"
              placeholder="Nombre completo"
            />
            <input
              type="email"
              class="form-control"
              v-model="form.email"
              placeholder="Correo electrónico"
            />
            <input
              type="text"
              class="form-control"
              v-model="form.phone"
              placeholder="Teléfono"
            />
            <textarea
              class="form-control"
              v-model="form.message"
              placeholder="Mensaje o dudas"
            />
            <div class="row">
              <div class="col col-12 col-md-4 mb-2">
                <button type="submit" class="btn btn-primary button-form">
                  Enviar
                  <i class="bi bi-arrow-right"></i>
                </button>
              </div>
              <div class="col col-12 col-md-8">
                <p class="tc-text-dark ts-small">
                  *Al enviar esta información el equipo de afiliación se
                  pondrá en contacto contigo para continuar con el proceso.
                </p>
              </div>
            </div>
            
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import emailjs from "@emailjs/browser";
import { emptyString } from "@/utils/emptyString";
import { emailValid } from "@/utils/emailValid";

export default {
  name: "ContactoHomeComp",
  data() {
    return {
      form: {
        name: "",
        email: "",
        phone: "",
        message: "",
      },
    };
  },
  methods: {
    validarForm() {
      if (emptyString(this.form.name))
        return "El nombre es requerido";
      if (emptyString(this.form.phone))
        return "El teléfono es requerido";
      if (emptyString(this.form.email))
        return "El correo electrónico es requerido";
      if (!emailValid(this.form.email))
        return "El correo electrónico no es válido";
      return true;
    },
    sendEmail(e) {
      if (this.validarForm() == true) {
        this.error = "Enviando...";
        emailjs
          .send(
            "aspi_gmail_service",
            "aspi_contact_template",
            {
              type: "Afiliación",
              name: this.form.name,
              phone: this.form.phone,
              email: this.form.email,
              message: this.form.message,
            },
            "qMYNrezbauUPILqnP"
          )
          .then(
            (result) => {
              this.error = "Mensaje enviado correctamente";
              console.log("SUCCESS!", result.status, result.text);
              this.form.email = "";
              this.form.name = "";
              this.form.phone = "";
              this.form.message = "";
            },
            (error) => {
              this.error = "Algo salio mal, intenta de nuevo";
              console.log("FAILED...", error);
            }
          );
      } else {
        this.error = this.validarForm();
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.contacto-container {
  position: relative;
  background-color: var(--color-gray);
  height: fit-content;
  width: 100%;
  padding: 5rem 8rem 2rem;

  .white-space {
    height: 200px;
    width: 100%;
  }

  .contact-img {
    position: absolute;
    bottom: -6rem;
    left: 0;
    width: 80%;
    height: 95%;
    object-fit: cover;
    object-position: center;
  }

  .button-option {
    background-color: transparent;
    border: 1px solid var(--color-primary);
    color: var(--color-primary);
    border-radius: 2rem;
    padding: 0rem 1.3rem;
    cursor: pointer;
    transition: var(--transition-fast);

    &:hover {
      transform: scale(0.98);
      opacity: 0.7;
    }
  }
  .button-option.active {
    background-color: var(--color-primary);
    color: var(--color-white);
  }

  .form-control {
    border-radius: 0;
    border: none;
    background: none;
    border-bottom: 1px solid var(--color-text);
    padding: 0.5rem;
    margin-bottom: 2rem;
    &:focus {
      outline: none;
    }
  }
  .button-form {
    background-color: var(--color-primary);
    border: none;
    color: white;
    border-radius: 2rem;
    padding: 0.4rem 2rem;
  }
}

@media screen and (max-width: 992px) {
  .contacto-container {
    padding: 5rem 2rem 2rem;
    .white-space {
      display: none;
    }
    .contact-img {
      position: relative;
      bottom: 0;
      width: 100%;
      height: 200px;
      margin: 12px 0;
    }
  }
}
</style>