<template>
  <div class="contacto-view">
    <NavBar @toggleMenu="toggleMenu"/>
    <div class="container pt-5">
      <div class="row">
        <div class="col-12 col-lg-6 mb-5 px-4 px-md-5" data-aos="fade-right" data-aos-duration="1000">
          <div class="info-card br-bigger">
            <MapComp class="mb-4"/>
            <h5 class="ff-grandis">Ubicación</h5>
            <a
              href="https://www.google.com/maps/dir/?api=1&destination=24.151805171052%2C-110.30908584595&fbclid=IwAR3FrvJXcnFHGA_trgyuEJbGs8eXD1DjF3SmfIkWLeVo9byh7EbyKwpLQcY"
              class="contact-link tc-text-dark"
            >
              <i class="bi bi-geo-alt"></i>
              Nicolas Bravo #1020, C.P. 23000, La Paz, Baja California Sur, México
            </a>
            <div class="row pt-4">
              <div class="col-12 col-sm-4 mb-4">
                <h5 class="ff-grandis">Teléfono</h5>
                <a href="tel:6121383999" class="contact-link tc-text-dark">
                  +52 (612) 138 3999
                </a>
              </div>

              <div class="col-12 col-sm-4 mb-4">
                <h5 class="ff-grandis">Correo</h5>
                <a href="mailto:contacto@aspi.mx" class="contact-link tc-text-dark">
                  contacto@aspi.mx
                </a>
              </div>

              <div class="col-12 col-sm-4 mb-4">
                <h5 class="ff-grandis">Redes</h5>
                <div class="d-flex gap-2">
                  <a href="https://www.facebook.com/aspi.lapaz.bcs" target="_blank" class="contact-link ts-h3">
                    <i class="bi bi-facebook"></i>
                  </a>
                  <!-- <a href="#" class="contact-link ts-h3">
                    <i class="bi bi-instagram"></i>
                  </a> -->
                  <a href="#" class="contact-link ts-h3">
                    <i class="bi bi-whatsapp"></i>
                  </a>
                  <!-- <a href="https://api.whatsapp.com/send?phone=5216121005923&text=Hola%2C%20quiero%20m%C3%A1s%20informaci%C3%B3n%20de%20ASPI%20y%20OMNI%20MLS" target="_blank" class="contact-link ts-h3">
                    <i class="bi bi-whatsapp"></i>
                  </a> -->
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="col-12 col-lg-6" data-aos="fade-left" data-aos-duration="1000" data-aos-delay="300">
          <ContactForm />
        </div>
      </div>
    </div>
    <div class="container py-5">
      <div class="buzon-container br-bigger">
        <div class="row">
          <div class="col-12 col-lg-6 position-relative">
            <h2 class="ff-grandis tw-medium ts-h1" data-aos="fade-right" data-aos-duration="800">
              Buzón de quejas y sugerencias
            </h2>
            <div class="white-space"></div>
            <img
              data-aos="fade-right"
              data-aos-duration="800"
              data-aos-delay="100"
              class="buzon-img br-big"
              src="@/assets/img/bg_search_home.jpg"
              alt=""
            />
          </div>

          <div class="col-12 col-lg-6 position-relative" data-aos="fade-left" data-aos-duration="1000">
            <buzon-quejas-form />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import NavBar from '@/components/general/NavBar.vue'
import MapComp from '@/components/contact/MapComp.vue'
import ContactForm from '@/components/contact/ContactForm.vue'
import BuzonQuejasForm from '@/components/contact/BuzonQuejasForm.vue'
export default {
  components: {
    NavBar,
    MapComp,
    ContactForm,
    BuzonQuejasForm
  },
  metaInfo: {
    title: 'ASPI',
    titleTemplate: '%s | Contáctanos'
  },
  methods: {
    toggleMenu() {
      this.$emit('toggleMenu')
    },
  },
}
</script>

<style lang="scss" scoped>
.contacto-view {
  .contact-link {
    transition: var(--transition-fast);
    &:hover {
      color: var(--color-primary);
    }
  }

  .info-card {
    background-color: var(--color-gray);
    height: fit-content;
    width: 100%;
    padding: 1.5rem;
    box-shadow: 0 4px 24px rgba(0, 0, 0, 0.05);
  }

  .main-header {
    background-image: url('@/assets/img/contact_header.jpg');
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    height: fit-content;
    padding: 120px 0 48px;
    position: relative;

    &::before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: rgba($color: #ffffff, $alpha: 0.7);
    }
  }

  .buzon-container {
    position: relative;
    background-color: var(--color-gray);
    height: fit-content;
    width: 100%;
    padding: 5rem 8rem 2rem;

    .white-space {
      height: 200px;
      width: 100%;
    }

    .buzon-img {
      position: absolute;
      bottom: -6rem;
      left: 0;
      width: 80%;
      height: 95%;
      object-fit: cover;
      object-position: center;
    }
  }
  @media screen and (max-width: 992px) {
    .buzon-container {
      padding: 5rem 2rem 2rem;
      .white-space {
        display: none;
      }
      .buzon-img {
        position: relative;
        bottom: 0;
        width: 100%;
        height: 200px;
        margin: 12px 0;
      }
    }
  }
}

</style>