<template>
  <div class="contact-form px-5">
    <h1 class="ff-grandis tc-text-light">Contáctanos</h1>
    <p class="tc-text-dark mb-5">
      Si tienes alguna duda o quieres saber más sobre nosotros, contáctanos
      llenando el siguiente formulario.
    </p>
    <form @submit.prevent="sendEmail()">
      <input
        type="text"
        class="form-control"
        v-model="form.name"
        placeholder="Nombre completo"
      />
      <input
        type="email"
        class="form-control"
        v-model="form.email"
        placeholder="Correo electrónico"
      />
      <input
        type="text"
        class="form-control"
        v-model="form.phone"
        placeholder="Teléfono"
      />
      <textarea
        class="form-control"
        v-model="form.message"
        placeholder="Mensaje o dudas"
      />

      <div class="row">
        <div class="col col-12">
          <p class="tc-text-dark ts-small">
            {{ error }}
          </p>
        </div>
      </div>

      <div class="row">
        <div class="col col-12 col-md-4 mb-2">
          <button type="submit" class="btn btn-primary button-form">
            Enviar
            <i class="bi bi-arrow-right"></i>
          </button>
        </div>
        <div class="col col-12 col-md-8">
          <p class="tc-text-dark ts-small">
            *Al enviar esta información el equipo de afiliación se pondrá en
            contacto contigo para resolver tus dudas o preguntas.
          </p>
        </div>
      </div>
    </form>
  </div>
</template>

<script>
import emailjs from "@emailjs/browser";
import { emptyString } from "@/utils/emptyString";
import { emailValid } from "@/utils/emailValid";
export default {
  name: "ContactForm",
  data() {
    return {
      error: "",
      form: {
        name: "",
        email: "",
        phone: "",
        message: "",
      },
    };
  },
  methods: {
    validarForm() {
      if (emptyString(this.form.name))
        return "El nombre es requerido";
      if (emptyString(this.form.phone))
        return "El teléfono es requerido";
      if (emptyString(this.form.email))
        return "El correo electrónico es requerido";
      if (!emailValid(this.form.email))
        return "El correo electrónico no es válido";
      return true;
    },
    sendEmail(e) {
      if (this.validarForm() == true) {
        this.error = "Enviando...";
        emailjs
          .send(
            "aspi_gmail_service",
            "aspi_contact_template",
            {
              type: "Contacto",
              name: this.form.name,
              phone: this.form.phone,
              email: this.form.email,
              message: this.form.message,
            },
            "qMYNrezbauUPILqnP"
          )
          .then(
            (result) => {
              this.error = "Mensaje enviado correctamente";
              console.log("SUCCESS!", result.status, result.text);
              this.form.email = "";
              this.form.name = "";
              this.form.phone = "";
              this.form.message = "";
            },
            (error) => {
              this.error = "Algo salio mal, intenta de nuevo";
              console.log("FAILED...", error);
            }
          );
      } else {
        this.error = this.validarForm();
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.contact-form {
  padding: 1rem;
}
.form-control {
  border-radius: 0;
  border: none;
  background: none;
  border-bottom: 1px solid var(--color-text);
  padding: 0.5rem;
  margin-bottom: 2rem;
  &:focus {
    outline: none;
    box-shadow: none;
    border-bottom: 1px solid var(--color-primary);
    &::placeholder {
      color: var(--color-primary);
    }
  }
}

.button-form {
  background-color: var(--color-primary);
  border: none;
  color: white;
  border-radius: 2rem;
  padding: 0.4rem 2rem;
}
</style>
