<template>
  <div class="container px-5" style="margin-top: 98px; margin-bottom: 48px;">
    <div class="row px-5">
      <div class="col col-6 position-relative">
        <h6 class="tw-regular mb-5" data-aos="fade-right" data-aos-duration="800">/ BOLSA INMOBILIARIA COMPARTIDA</h6>
        <h2 class="ff-grandis ts-big tw-medium mb-5" data-aos="fade-right" data-aos-duration="800">Compra o vende con profesionales del sector</h2>
        <p class="tc-text-light" data-aos="fade-right" data-aos-duration="800">
          Lorem ipsum, dolor sit amet consectetur adipisicing elit. Ut atque
          earum nobis? Nisi consectetur esse recusandae ipsum cupiditate, dicta
          explicabo nemo mollitia, unde iste consequatur id accusamus modi
          voluptatem obcaecati.
        </p>

        <div class="search-bar br-big" data-aos="fade-up" data-aos-duration="1000">
          <!-- TIPO DE OPERACION -->
          <div class="search-input">
            <span class="ff-grandis tc-text-lighter tw-medium">Operación</span>
            <div class="dropdown">
              <button class="btn button-drop dropdown-toggle tc-text-dark" type="button" id="input-search-type" data-bs-toggle="dropdown" aria-expanded="false">
                {{ searchObject.operation }}
              </button>
              <ul class="dropdown-menu" aria-labelledby="input-search-type">
                <li v-for="item in searchOperation" :key="item">
                  <button class="w-100 bg-transparent ta-left" @click="setOperation(item)">
                    <a class="dropdown-item">{{ item }}</a>
                  </button>
                </li>
              </ul>
            </div>
          </div>
          <!-- UBICACION -->
          <div class="search-input">
            <span class="ff-grandis tc-text-lighter tw-medium">Ubicación</span>
            <div class="dropdown">
              <button class="btn button-drop dropdown-toggle tc-text-dark" type="button" id="input-search-type" data-bs-toggle="dropdown" aria-expanded="false">
                {{ searchObject.location }}
              </button>
              <ul class="dropdown-menu" aria-labelledby="input-search-type">
                <li v-for="item in searchLocation" :key="item">
                  <button class="w-100 bg-transparent ta-left" @click="setLocation(item)">
                    <a class="dropdown-item">{{ item }}</a>
                  </button>
                </li>
              </ul>
            </div>
          </div>
          <!-- TIPO DE PROPIEDAD -->
          <div class="search-input">
            <span class="ff-grandis tc-text-lighter tw-medium">Tipo de propiedad</span>
            <div class="dropdown">
              <button class="btn button-drop dropdown-toggle tc-text-dark" type="button" id="input-search-type" data-bs-toggle="dropdown" aria-expanded="false">
                {{ searchObject.type }}
              </button>
              <ul class="dropdown-menu" aria-labelledby="input-search-type">
                <li v-for="item in searchProperties" :key="item">
                  <button class="w-100 bg-transparent ta-left" @click="setType(item)">
                    <a class="dropdown-item">{{ item }}</a>
                  </button>
                </li>
              </ul>
            </div>
          </div>
          <!-- PRECIO -->
          <div class="search-input">
            <span class="ff-grandis tc-text-lighter tw-medium">Precio</span>
            <div class="d-flex">
              <money class="tc-text-dark tw-bold" v-model="searchObject.price" v-bind="money">{{searchObject.price}}</money>
              <span class="tc-text-lighter tw-bold">MXN</span>
            </div>
          </div>
          <!-- BUSCAR BOTON -->
          <button class="btn btn-primary button-search">
            Buscar
          </button>
        </div>
      </div>
      <div class="col col-1"></div>
      <div class="col col-5" data-aos="fade-left" data-aos-duration="800">
        <div class="bolsa-container">
          <button class="btn btn-primary tw-medium br-medium">
            Ver todo
            <i class="bi bi-arrow-right"></i>
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Money } from 'v-money'
export default {
  name: "BuscadorHomeComp",
  components: {
    Money
  },
  data() {
    return {
      money: {
        decimal: '.',
        thousands: "'",
        prefix: '$ ',
        suffix: '',
        precision: 2,
        masked: false
      },
      searchOperation: [ "Compra", "Renta" ],
      searchLocation: [ "La Paz, Baja California Sur", "Los Cabos, Baja California Sur" ],
      searchProperties: [ 'Casa', 'Departamento', 'Oficina', 'Local', 'Terreno', 'Edificio', 'Cualquiera' ],
      searchObject: {
        operation: "Compra",
        location: "La Paz, Baja California Sur",
        type: "Cualquiera",
        price: "$1,000,000",
      }
    }
  },
  methods: {
    setOperation(operation) {
      this.searchObject.operation = operation
    },
    setLocation(location) {
      this.searchObject.location = location
    },
    setType(type) {
      this.searchObject.type = type
    },
  },
};
</script>

<style lang="scss" scoped>
.bolsa-container {
  background-image: url("@/assets/img/bg_search_home.jpg");
  background-size: cover;
  background-position: right center;
  background-repeat: no-repeat;
  border-radius: 2rem;
  padding: 1rem;
  height: 600px;
  width: 100%;
  text-align: right;
  button {
    background-color: var(--color-white);
    color: var(--color-primary);
    border: none;
    width: 128px;
  }
}

.search-bar {
  position: absolute;
  bottom: 10%;
  left: 0;
  background-color: var(--color-white);
  box-shadow: var(--bs-normal);
  padding: 1rem 2rem;
  height: 100px;
  width: 180%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  z-index: 100;

  .button-search {
    background-color: var(--color-primary);
    font-family: var(--grandis-font);
    font-weight: var(--font-bold);
    color: var(--color-white);
    border: none;
    width: 98px;
  }

  .search-input {
    width: fit-content;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    .button-drop {
      background-color: transparent !important;
      font-weight: var(--font-medium);
      border: none !important;
      padding: 0;
    }
  }
}
</style>