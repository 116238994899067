<template>
  <div id="header_home_comp">
    <div class="header-container">
      <HomeNavBar @toggleMenu="toggleMenu" />
      <splide :options="options">
        <splide-slide>
          <img class="splide-images" src="@/assets/img/slider-1.jpg">
        </splide-slide>
        <splide-slide>
          <img class="splide-images" src="@/assets/img/slider-2.webp">
        </splide-slide>
        <splide-slide>
          <img class="splide-images" src="@/assets/img/slider-3.jpeg">
        </splide-slide>
        <splide-slide>
          <img class="splide-images" src="@/assets/img/slider-4.jpeg">
        </splide-slide>
      </splide>
      <div class="container md-container px-4 px-md-0">
        <h4
          class="tc-white ts-normal tw-medium mb-4"
          data-aos="fade-up"
          data-aos-duration="1500"
        >
          / CONÓCENOS
        </h4>
        <h1
          class="ff-grandis tc-white tw-medium w-80 mb-3 md-text"
          data-aos="fade-up"
          data-aos-duration="1500"
          data-aos-delay="150"
        >
          Somos la Asociación Sudcaliforniana de Profesionales Inmobiliarios.
        </h1>
        <div
          class="d-flex align-items-center gap-3"
          data-aos="fade-up"
          data-aos-duration="1500"
          data-aos-delay="300"
        >
          <span class="tc-white">Descubrir</span>
          <button class="btn button-icon">
            <i class="bi bi-arrow-down"></i>
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import HomeNavBar from "@/components/general/HomeNavBar.vue";
import { Splide, SplideSlide } from '@splidejs/vue-splide';
import '@splidejs/splide/dist/css/splide.min.css';

export default {
  name: "HeaderHomeComp",
  components: {
    HomeNavBar,
    Splide,
    SplideSlide
  },
  data() {
    return {
      options: {
        type   : 'fade',
        rewind : true,
        interval: 4000,
        arrows: false,
        autoplay: true,
        pagination: false,
      },
    };
  },
  methods: {
    toggleMenu() {
      this.$emit("toggleMenu");
    },
  },
};
</script>

<style lang="scss" scoped>
#header_home_comp {
  width: 100%;
  height: 100vh;
  padding: 1.5rem;

  .header-container {
    width: 100%;
    height: 100%;
    //background-image: url("~@/assets/img/bg_header_home.jpg");
    // background-size: cover;
    // background-position: center top;
    // background-repeat: no-repeat;
    border-radius: 2rem;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    padding: 2rem 0 4rem;
    position: relative;

    .splide {
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      z-index: -1;
    }
    .splide-images {
      width: 100%;
      height: 100%;
      object-fit: cover;
      border-radius: 2rem;
      filter: brightness(0.8);
    }

    .w-80 {
      width: 90%;
    }

    .md-container {
        //height: 100%;
        .md-text {
          font-size: 4rem;
        }
      }

    .button-icon {
      background-color: var(--color-primary);
      width: 48px;
      height: 48px;
      border: none;
      color: #fff;
      font-size: 1.4rem;
      border-radius: 50%;
      transition: all 0.3s ease-in-out;
      cursor: pointer;

      &:hover {
        background-color: #fff;
        color: #000;
      }
    }
  }
}
@media only screen and (max-width: 1100px) {
  #header_home_comp {
    padding: 1rem;
    height: 90vh;
    .header-container {
      padding: 2rem 1rem;
      //justify-content: flex-start;
      .md-container {
        //height: 100%;
        .md-text {
          font-size: 3rem;
        }
      }
    }
  }
}

@media only screen and (max-width: 768px) {
  #header_home_comp {
    padding: 1rem;
    height: 90vh;
    .header-container {
      padding: 2rem 1rem;
      .md-container {
        //height: 80%;
        padding-bottom: 2rem;
        .md-text {
          font-size: 2rem;
          width: 100% !important;
        }
      }
    }
  }
}
</style>
