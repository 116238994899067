import { render, staticRenderFns } from "./OrganiPerson.vue?vue&type=template&id=520d0b35&scoped=true&"
import script from "./OrganiPerson.vue?vue&type=script&lang=js&"
export * from "./OrganiPerson.vue?vue&type=script&lang=js&"
import style0 from "./OrganiPerson.vue?vue&type=style&index=0&id=520d0b35&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "520d0b35",
  null
  
)

export default component.exports