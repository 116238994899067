<template>
  <div class="container px-4 px-md-0 py-5">
    <div class="row px-0 px-lg-5 pb-5">
      <div class="col-0 col-md-2"></div>
      <div
        class="col col-12 col-md-8 order-md-0 order-1"
      >
       <div class="d-flex h-100 flex-column justify-content-center align-items-center">
         <video-player  class="video-player-box"
           ref="videoPlayer"
           :options="playerOptions"
           :playsinline="true"
         >
         </video-player>
       </div>
      </div>
      <div class="col-0 col-md-2"></div>
    </div>

    <div class="row px-0 px-lg-5 mb-5">
      <div
        class="col col-12 col-lg-5 d-none d-lg-flex flex-column justify-content-center"
        data-aos="fade-right"
        data-aos-duration="1000"
      >
        <p>/ AFILIADOS Y COLABORADORES</p>
        <div style="height: 80px"></div>
        <ICountUp
          class="ts-max tc-primary ff-grandis tw-regular line-h-05"
          :delay="delay"
          :endVal="endVal"
          :options="options"
          @ready="onReady"
        />
      </div>
      <div
        class="col col-12 col-md-7 col-lg-7 col-xl-6 text-end pb-0 py-lg-5 order-0 order-md-1"
        data-aos="zoom-in"
        data-aos-duration="1000"
      >
        <h2 class="ff-grandis ts-big tw-medium mb-3">¿Quiénes somos?</h2>
        <p class="ts-h3 mb-3 tc-text-light">
          Somos un grupo de profesionales inmobiliarios en La Paz, Baja California Sur,
          comprometidos a ayudar a nuestros clientes a lograr sus objetivos de
          propiedad con un enfoque personalizado y conocimiento del mercado
          local.
        </p>

        <router-link to="/afiliacion" class="btn button-normal">
          Afíliate con nosotros
          <i class="bi bi-arrow-right"></i>
        </router-link>
      </div>

      <div
        class="col col-12 col-md-5 d-flex d-lg-none flex-column justify-content-center mt-5"
        data-aos="fade-right"
        data-aos-duration="1000"
      >
        <p>/ AFILIADOS Y COLABORADORES</p>
        <div style="height: 50px"></div>
        <ICountUp
          class="ts-biggest tc-primary ff-grandis tw-regular line-h-05"
          :delay="delay"
          :endVal="endVal"
          :options="options"
          @ready="onReady"
        />
      </div>
    </div>
  </div>
</template>

<script>
import ICountUp from "vue-countup-v2";
import 'video.js/dist/video-js.css'
import { videoPlayer } from 'vue-video-player'

export default {
  name: "QuienesSomosHomeComp",
  components: {
    ICountUp,
    videoPlayer
  },
  data() {
    return {
      counterInstance: null,
      delay: 1000,
      endVal: 63,
      options: {
        useEasing: true,
        useGrouping: true,
        separator: ",",
        decimal: ".",
        prefix: "",
        suffix: "+",
      },
      playerOptions: {
        // videojs options
        autoplay: true,
        muted: true,
        sources: [{
          type: "video/mp4",
          src: "/static/video_1.MP4"
        }],
      }
    };
  },
  mounted() {
    console.log('this is current player instance object', this.player)
  },
  computed: {
    player() {
      return this.$refs.videoPlayer.player
    }
  },
  // mounted() {
  //   window.addEventListener("scroll", this.onScroll($event));
  // },
  // beforeDestroy() {
  //   window.removeEventListener("scroll", this.onScroll());
  // },
  methods: {
    onReady: function (instance, CountUp) {
      this.counterInstance = instance;
      instance.update(instance.endVal);
    },
    // onScroll(e) {
    //   const windowTop = e.target.documentElement.scrollTop;
    //   if (windowTop > window.innerHeight && windowTop < window.innerHeight * 2) {
    //     this.counterInstance.reset();
    //     this.counterInstance.update(this.countInstance.endVal);
    //   }
    // },
  },
};
</script>

<style lang="scss" scoped>
.button-normal {
  background-color: transparent !important;
  font-weight: var(--font-medium);
  color: var(--color-primary);
  border: none !important;
  padding: 0;
  i {
    transition: var(--transition-fast);
  }
  &:hover {
    i {
      margin-left: 12px;
    }
  }
}

.video-player-box {
  width: 100%;
  border-radius: 10px;
  overflow: hidden;
}

::v-deep .video-js {
  width: 100% !important;
  height: 100% !important;
  aspect-ratio: 16/9 !important;
}
</style>
