<template>
  <div class="container p-5">
    <div class="row">
      <div
        class="col col-12 col-lg-4 order-1 order-lg-0 d-flex justify-content-center d-lg-block"
      >
        <img
          class="compromiso-image br-bigger"
          src="@/assets/img/baja_california_sur.jpg"
          data-aos="fade-right"
          data-aos-duration="1000"
          alt=""
        />
      </div>
      <div class="col col-12 col-lg-8 py-5 p-lg-5">
        <h2
          class="ff-grandis ts-big tw-medium mb-4"
          data-aos="fade-left"
          data-aos-duration="800"
        >
          Nuestra visión
        </h2>
        <p data-aos="fade-left" data-aos-duration="800" data-aos-delay="100">
          Nuestra visión es convertirnos en un referente de excelencia y ética
          en el mercado inmobiliario de Baja California Sur, a través de un
          enfoque colaborativo e innovador. Para lograr nuestra visión,
          enfatizamos los siguientes puntos clave:
        </p>
        <div class="row py-4">
          <div
            class="col col-6 px-0"
            data-aos="fade-left"
            data-aos-duration="800"
            data-aos-delay="200"
          >
            <ul class="compromiso-lista">
              <li>
                Promover y fomentar la profesionalización de los profesionales
                inmobiliarios.
              </li>
              <li>
                Colaborar con otros actores clave del mercado inmobiliario en la
                región para lograr un mercado más eficiente y efectivo.
              </li>
              <li>
                Ofrecer herramientas y tecnologías innovadoras a nuestros
                miembros, para mejorar la calidad de su servicio y su capacidad
                de hacer negocios.
              </li>
            </ul>
          </div>
          <div
            class="col col-6 px-0"
            data-aos="fade-left"
            data-aos-duration="800"
            data-aos-delay="300"
          >
            <ul class="compromiso-lista mb-4">
              <li>
                Fomentar un ambiente de colaboración y apoyo entre los miembros
                de la asociación.
              </li>
              <li>
                Contribuir al desarrollo económico y social de Baja California
                Sur, mediante la promoción de inversiones inmobiliarias
                responsables y sostenibles.
              </li>
              <li>
                Ser un referente en la formación y educación de los
                profesionales inmobiliarios en Baja California Sur.
              </li>
            </ul>
            <button class="btn btn-primary button-compromiso">
              Leer más
              <i class="bi bi-arrow-right"></i>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "CompromisoHomeComp",
  data() {
    return {
      chartData: {},
    };
  },
};
</script>

<style lang="scss" scoped>
.compromiso-image {
  position: relative;
  width: 100%;
  max-width: 380px;
  object-fit: cover;
  object-position: center;
  margin-top: -5rem;
  @media screen and (max-width: 992px) {
    margin-top: 0;
    margin: 0 auto;
  }
}
.button-compromiso {
  background-color: var(--color-primary);
  color: white;
  border-radius: 2rem;
  border: none;
  width: 80%;
  margin: 0 10%;
}
.compromiso-lista {
  list-style: none;
  li {
    margin-bottom: 1rem;
    position: relative;
    &:before {
      content: "";
      padding-right: 5px;
      width: 6px;
      height: 6px;
      background-color: var(--color-primary);
      border-radius: 50%;
      position: absolute;
      top: calc(50% - 3px);
      left: -18px;
    }
  }
}
.banner-presidente {
  position: relative;
  background-color: var(--color-primary);
  height: 260px;
  width: 100%;
  overflow: hidden;
  padding: 2rem;

  .logo {
    width: 98px;
  }

  .banner-circle {
    position: absolute;
    top: 50%;
    left: 10%;
    transform: translate(-50%, -50%);
    width: 400px;
    aspect-ratio: 1;
    border-radius: 50%;
    background-color: var(--color-primary-dark);
    border: 2px solid rgba(255, 255, 255, 0.3);
  }
  .banner-image {
    position: relative;
    width: 112px;
    height: 112px;
    border-radius: 50%;
    border: 3px solid rgba(255, 255, 255, 0.3);
  }
}
</style>
