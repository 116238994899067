<template>
  <div class="noticias-view">
    <NavBar @toggleMenu="toggleMenu" />
    <div class="container pt-5 px-5 px-md-0" data-aos="fade-in" data-aos-duration="1000">
      <div class="row mb-5">
        <p class="mb-3 text-uppercase tc-text-lighter">/ Conoce ASPI</p>
        <h1 class="ff-grandis tc-text-light">Noticias</h1>
      </div>
    </div>

    <div class="container">
      <div class="row">
        <div class="col-12 col-xl-7 mb-5">
          <div class="row px-sm-5 mb-4">
            <div class="col-12" data-aos="fade-in" data-aos-duration="1000" data-aos-delay="200">
              <video-player  class="video-player-box"
                ref="videoPlayer"
                :options="playerOptions"
                :playsinline="true"
              >
              </video-player>
            </div>
          </div>

          <div class="system-grid px-sm-5">
            <div class="card-noticia" v-for="item in noticias" :key="item.id" :class="[ 
                item.id == 0 ? 'small-card-left' : '',
                item.id == 1 ? 'large-card-right' : '',
                item.id == 2 ? 'large-card-left' : '',
                item.id == 3 ? 'small-card-right' : '',         
              ]"
            >
              <div class="card-noticia-body">
                <div>
                  <h3 class="ff-grandis tw-medium ts-h3" :class="{ 'tc-white' : item.id == 3}">{{ item.titulo }}</h3>
                  <p class="tw-regular parrafo" :class="{ 'tc-white' : item.id == 3}">{{ item.descripcion }}</p>
                </div>
                <a :href="item.link" target="_blank" class="button-noticia" :class="{ 'tc-white' : item.id == 3}">Leer más <i class="bi bi-arrow-right"></i></a>
              </div>
              <img class="card-noticia-img" :src="item.imagen" alt="Img" v-if="item.imagen">
            </div>
          </div>
        </div>
        <div class="col-12 col-xl-5">
          <iframe
            src="https://www.facebook.com/plugins/page.php?href=https%3A%2F%2Fwww.facebook.com%2Faspi.lapaz.bcs%2F%3Flocale%3Des_LA&tabs=timeline&width=500&height=700&small_header=false&adapt_container_width=true&hide_cover=false&show_facepile=false&appId" 
            height="700" width="500" style="border:none;overflow:hidden" scrolling="no" frameborder="0" allowfullscreen="true"
            small_header="false" adapt_container_width="true" hide_cover="false" show_facepile="false"
            allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share">
          </iframe> 
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import NavBar from '@/components/general/NavBar.vue'
import 'video.js/dist/video-js.css'
import { videoPlayer } from 'vue-video-player'

export default {
  components: {
    NavBar,
    videoPlayer
  },
  metaInfo: {
    title: 'ASPI',
    titleTemplate: '%s | Noticias'
  },
  data() {
    return {
      playerOptions: {
        muted: false,
        sources: [{
          type: "video/mp4",
          src: "/static/video_2.MP4"
        }],
      },
      noticias: [
        {
          id: 0,
          titulo: "Feliz día Internacional de La mujer",
          descripcion:
            "Feliz día Internacional de La mujer , muchas felicidades para todas nuestras asociadas y afiliadas de A.S.P.I. que nos llenan de orgullo todos los días con su profesionalismo y compromiso en el sector inmobiliario .",
          link: "https://www.facebook.com/aspi.lapaz.bcs/posts/pfbid0iGuucaKa8Kt7MTBWGtEVLFP76BP3w8hUM1MjkNAvQoqVAokMMnn2iPKVRN4nAF9fl",
        },
        {
          id: 1,
          titulo: "Toma de Protesta 2023",
          imagen: "https://i.ibb.co/H2xmSq0/news-image-1.jpg",
          descripcion: "Toma de protesta del nuevo consejo directivo ASPI 2023 - 2024.",
          link: "https://www.facebook.com/aspi.lapaz.bcs/posts/pfbid032C3dXjiz8b1QcREw5nBrPZX5uyC8twUeTcsn8Kf4KTSHbAxgcnZVoTgeosMLf4N7l",
        },
        {
          id: 2,
          titulo: "Asamblea ASPI 2023",
          imagen: "https://i.ibb.co/7WJsd6q/news-image-2.jpg",
          descripcion:
            "ASAMBLEA  ASPI 27 DE FEBRERO GRACIAS POR SU PRESENCIA , JUNTOS SOMOS MÁS FUERTES !!!!",
          link: "https://www.facebook.com/aspi.lapaz.bcs/posts/pfbid02djc8CiknPqw7LBkKTXGBmGNQdLBWSSqQeTrAdRmXZdc5uA5vuUyXcbWfE9Mh1VKLl",
        },
        {
          id: 3,
          titulo: "Última Asamblea del año 2022",
          descripcion:
            "El día de ayer celebramos nuestra última Asamblea del año, bajo el cargo de nuestra Presidenta del período 2022-2023, la Lic. Lourdes Guadalupe Muñoz Alvarez. Quien cedió la estafeta al Presidente electo 2023-2024, José Carlos González  Macias. Director de Century 21 Insignia. ",
          link: "https://www.facebook.com/aspi.lapaz.bcs/posts/pfbid02djc8CiknPqw7LBkKTXGBmGNQdLBWSSqQeTrAdRmXZdc5uA5vuUyXcbWfE9Mh1VKLl",
        },
      ],
    }
  },
  methods: {
    toggleMenu() {
      this.$emit('toggleMenu')
    }
  },
}
</script>

<style lang="scss" scoped>
.system-grid {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-rows: 1fr 1fr;
  gap: 1rem;
  grid-template-areas:
    "small-card-left large-card-right large-card-right"
    "large-card-left large-card-left small-card-right";

  .card-noticia {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    padding: 1.5rem;
    border-radius: 1rem;
    min-height: 300px;

    .card-noticia-img {
      width: 200px;
      height: 100%;
      border-radius: 1rem;
      object-fit: cover;
      object-position: center;
    }

    .card-noticia-body {
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      padding: 1rem;

      .button-noticia {
        width: fit-content;
        height: fit-content;
        background: none;
        font-weight: var(--font-bold);
      }

      .parrafo {
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        line-clamp: 4;
        -webkit-line-clamp: 4;
        -webkit-box-orient: vertical;
      }
    }
  }

  .small-card-left {
    grid-area: small-card-left;
    background-color: var(--color-lighter-secondary);
  }

  .large-card-right {
    grid-area: large-card-right;
    background-color: var(--color-light-primary);
  }

  .large-card-left {
    grid-area: large-card-left;
    background-color: var(--color-light-secondary);
  }

  .small-card-right {
    grid-area: small-card-right;
    background-color: var(--color-primary);
  }
}

@media only screen and (max-width: 768px) {
  .system-grid {
    grid-template-columns: 1fr;
    grid-template-rows: 1fr 1fr 1fr 1fr;
    grid-template-areas:
      "small-card-left"
      "large-card-right"
      "small-card-right"
      "large-card-left";
  }
}

.button-ver-todas {
  width: fit-content;
  height: fit-content;
  background: none;
  font-weight: var(--font-bold);
  color: var(--color-text-lighter);
}

.video-player-box {
  width: 100%;
  border-radius: 10px;
  overflow: hidden;
}

::v-deep .video-js {
  width: 100% !important;
  height: 100% !important;
  aspect-ratio: 16/9 !important;
}
</style>