<template>
  <div class="container mt-5">
    <div class="resultados-container br-bigger">
      <img
        class="resultados-img"
        src="@/assets/img/image_team.jpeg"
        alt=""
        data-aos="fade-left"
        data-aos-duration="1000"
      />
      <div class="row mb-4 mb-lg-3">
        <div class="col col-12 col-md-8 col-lg-5 pt-3 pt-md-0" data-aos="fade-right" data-aos-duration="1000">
          <h2 class="ff-grandis tw-medium ts-big">
            Nuestra mision
          </h2>
          <p class="ts-h3 mb-0">Nuestra misión es profesionalizar el mercado inmobiliario de Baja California Sur</p>
        </div>
      </div>
      <div class="row pt-3 pt-lg-5">
        <div class="col col-12 col-md-4 px-4 mb-5 mb-md-2" data-aos="fade-right" data-aos-duration="800" data-aos-delay="50">
          <div class="w-100 bg-primary mb-3" style="height: 3px"></div>
          <h3 class="ff-grandis tc-text-light tw-medium mb-1">Establecer</h3>
          <p>
            estándares más altos de profesionalismo en el mercado inmobiliario de Baja California Sur, 
            capacitando y educando a los profesionales inmobiliarios para que brinden un servicio más completo y 
            ético a sus clientes.
          </p>
        </div>
        <div class="col col-12 col-md-4 px-4 mb-5 mb-md-2" data-aos="fade-right" data-aos-duration="800" data-aos-delay="200">
          <div class="w-100 bg-secondary mb-3" style="height: 3px"></div>
          <h3 class="ff-grandis tc-text-light tw-medium mb-1">Fomentar</h3>
          <p>
            la transparencia en el mercado inmobiliario de Baja California Sur, promoviendo prácticas justas 
            y honestas en todas las transacciones y operaciones inmobiliarias.
          </p>
        </div>
        <div class="col col-12 col-md-4 px-4 mb-5 mb-md-2" data-aos="fade-right" data-aos-duration="800" data-aos-delay="350">
          <div class="w-100 bg-primary mb-3" style="height: 3px"></div>
          <h3 class="ff-grandis tc-text-light tw-medium mb-1">Mejorar</h3>
          <p>
            la percepción de los clientes sobre el mercado inmobiliario de Baja California Sur, ofreciendo 
            una experiencia inmobiliaria más confiable, segura y satisfactoria para los compradores, vendedores y 
            arrendatarios.
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ResultadosHomeComp",
};
</script>

<style lang="scss" scoped>
.resultados-container {
  position: relative;
  background-color: var(--color-gray);
  height: fit-content;
  min-height: 500px;
  width: 100%;
  padding: 5rem 8rem 8rem;

  .resultados-img {
    position: absolute;
    top: -6rem;
    right: -6rem;
    width: 50%;
    height: 280px;
    object-fit: cover;
    object-position: center;
    border-radius: 1rem;
  }
}
@media only screen and (max-width: 960px) {
  .resultados-container {
    padding: 5rem 2rem 8rem;
    .resultados-img {
      top: -6rem;
      right: -6rem;
      width: 100%;
      height: 160px;
    }
  }
}
</style>