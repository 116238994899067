<template>
  <div id="app">
    <router-view/>
  </div>
</template>

<script>
import AOS from 'aos';
export default {
  mounted() {
    AOS.init();
  },
  metaInfo: {
    title: 'ASPI',
    titleTemplate: '%s | Asociación Sudcaliforniana de Profesionales Inmobiliarios A.C.'
  }
}
</script>

<style lang="scss">
@import '@/styles/fonts.css';
@import '@/styles/general.scss';
</style>
