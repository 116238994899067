<template>
  <div class="asociados-view">
    <NavBar @toggleMenu="toggleMenu" />
    <div class="container py-5 px-5 px-md-0">
      <div class="row mb-5">
        <p class="mb-3 text-uppercase tc-text-lighter">/ Nuestro gran equipo</p>
        <h1 class="ff-grandis tc-text-light">Asociados a ASPI</h1>
      </div>

      <div class="d-flex justify-content-center align-items-center flex-column mb-4 w-75" style="margin: 0 auto;">
        <div class="input-group mb-3">
          <input 
            type="text"
            class="form-control"
            placeholder="Buscar asociado"
            aria-label="Buscar asociado"
            aria-describedby="button-addon2"
            v-model="search"
          />
          <button class="btn btn-primary" type="button" id="button-addon2">
            <i class="bi bi-search"></i>
          </button>
        </div>
      </div>

      <div class="asociados-grid">
        <div v-for="asociado in filteredAsociados" :key="asociado.id" class="asociado-card" data-aos="fade-in"
          @click="showModal = true; selectedAsociado = asociado">
          <img v-if="asociado.img" :src="asociado.img" alt="" class="asociado-img" />
          <img v-else src="../assets/logo-aspi.svg" alt="" class="asociado-img" />
          <div class="asociado-info">
            <h6 class="ff-grandis tc-text-light mb-2">{{ asociado.name }}</h6>
            <!-- <p class="tc-text-dark mb-2">{{ 'La Paz, BCS' }}</p> -->
            <div class="d-flex gap-1">
              <a :href="asociado.fb_link" target="_blank" class="btn-icon"><i class="bi bi-facebook"></i></a>
              <a :href="`http://maps.google.com/?q=${asociado.location}`" target="_blank" class="btn-icon"><i class="bi bi-geo-alt-fill"></i></a>
              <a :href="`tel:${asociado.phone}`" class="btn-icon"><i class="bi bi-telephone-fill"></i></a>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="modal-info" v-if="showModal" @click.self="closeModal">
      <div class="modal-info-card">
        <div class="modal-info-card__body">
          <div class="row">
            <div class="col-12 col-sm-4">
              <img v-if="selectedAsociado.img" :src="selectedAsociado.img" alt="" />
              <img v-else src="../assets/logo-aspi.svg" alt="" class="asociado-img" />
            </div>
            <div class="col-12 col-sm-8">
              <h4 class="ff-grandis ts-h2">{{ selectedAsociado.name }}</h4>
              <h5 class="ff-grandis ts-normal">Ubicación</h5>
              <a class="contact-link tc-text-dark">
                <i class="bi bi-geo-alt"></i>
                {{ selectedAsociado.location }}
              </a>

              <div class="row pt-4">
                <div class="col-12 mb-4">
                  <h5 class="ff-grandis ts-normal">Teléfono</h5>
                  <a :href="`tel:${selectedAsociado.phone}`" class="contact-link tc-text-dark">
                    {{ selectedAsociado.phone }}
                  </a>
                </div>

                <div class="col-12 mb-4">
                  <h5 class="ff-grandis ts-normal">Correo</h5>
                  <a :href="`mailto:${selectedAsociado.email}`" class="contact-link tc-text-dark">
                    {{ selectedAsociado.email }}
                  </a>
                </div>

                <div class="col-12 mb-4" v-if="selectedAsociado.website">
                  <h5 class="ff-grandis ts-normal">Sitio web</h5>
                  <a :href="`${selectedAsociado.website}`" target="_blank" class="contact-link tc-text-dark">
                    {{ selectedAsociado.website }}
                  </a>
                </div>

                <div class="col-12">
                  <h5 class="ff-grandis ts-normal">Redes sociales</h5>
                  <div class="d-flex gap-2">
                    <a :href="selectedAsociado.fb_link" target="_blank" class="contact-link ts-h3">
                      <i class="bi bi-facebook"></i>
                    </a>
                    <!-- <a href="https://www.instagram.com/aspi.lapaz.bcs/" target="_blank" class="contact-link ts-h3">
                      <i class="bi bi-instagram"></i>
                    </a> -->
                    <a :href="selectedPhone" target="_blank" class="contact-link ts-h3">
                      <i class="bi bi-whatsapp"></i>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <button class="btn-close" @click="closeModal">
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import NavBar from "@/components/general/NavBar.vue";
import Asociados from "@/data/asociados.json";

export default {
  components: {
    NavBar,
  },
  metaInfo: {
    title: 'ASPI',
    titleTemplate: '%s | Asociados'
  },
  data: () => ({
    selectedAsociado: {},
    showModal: false,
    asociados: Asociados,
    search: ''
  }),
  computed: {
    selectedPhone() {
      return `https://api.whatsapp.com/send?phone=52${this.selectedAsociado.phone}&text=Hola%2C%20quiero%20m%C3%A1s%20informaci%C3%B3n%20de%20ASPI`
    },
    filteredAsociados() {
      // return the filtered array that contains the search string in the name, location, or phone
      return this.asociados.filter(asociado => {
        return asociado.name.toLowerCase().includes(this.search.toLowerCase()) ||
          asociado.location.toLowerCase().includes(this.search.toLowerCase()) ||
          asociado.phone.toLowerCase().includes(this.search.toLowerCase())
      })
    }
  },
  methods: {
    toggleMenu() {
      this.$emit("toggleMenu");
    },
    closeModal() {
      this.showModal = false;
      this.selectedAsociado = null;
    }
  },
};
</script>

<style lang="scss" scoped>
.asociados-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  grid-gap: 1rem;
  padding: 1rem;

  .asociado-card {
    display: flex;
    align-items: center;
    justify-content: center;
    column-gap: 1rem;
    background-color: #fff;
    border-radius: 2rem;
    box-shadow: 0 0 24px rgba(0, 0, 0, 0.05);
    padding: 1rem;
    transition: all 0.3s ease;
    cursor: pointer;

    &:hover {
      transform: translateY(-5px);
      box-shadow: 0 0 24px rgba(0, 0, 0, 0.1);
    }

    .btn-icon {
      color: var(--color-primary);
    }

    .asociado-img {
      width: 96px;
      height: 96px;
      aspect-ratio: 1/1;
      object-fit: contain;
      border-radius: 50%;
    }
  }
}

.modal-info {
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 999;

  .modal-info-card {
    background-color: #fff;
    border-radius: 2rem;
    box-shadow: 0 0 24px rgba(0, 0, 0, 0.05);
    padding: 2rem;
    width: 100%;
    max-width: 480px;
    height: fit-content;
    min-height: 200px;
    position: relative;

    &__header {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      column-gap: 1rem;
      margin-bottom: 1rem;

    }

    button {
      position: absolute;
      top: 1rem;
      right: 1rem;
    }

    &__body {
      display: flex;
      align-items: center;
      justify-content: center;
      column-gap: 1rem;
      margin-bottom: 1rem;

      img {
        width: 100%;
        max-width: 260px;
        aspect-ratio: 1/1;
        object-fit: contain;
        border-radius: 1rem;
        margin-bottom: 2rem;
      }
    }
  }
}
</style>