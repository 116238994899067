<template>
  <div class="container mt-5">
    <div class="organigrama-comp br-bigger">
      <div class="row">
        <div class="col col-12 col-md-6 mb-5">
          <h2 class="ff-grandis tw-medium ts-h1 mb-2">Organigrama</h2>
          <p class="ts-normal tc-text-dark mb-0">
            El organigrama de la asociación incluye un equipo directivo y
            comités especializados para lograr los objetivos de ASPI.
          </p>
        </div>

        <div class="col col-12 d-none d-lg-block">
          <div class="d-flex flex-column align-items-center">
            <OrganiPerson :data="president" />
            <div class="line-connect"> <div class="line"></div> </div>
            <OrganiPerson :data="vice" />
            <div class="line-connect"> <div class="line"></div> </div>
          </div>
          <div class="main-line"> <div class="line"></div> </div>
          <div class="sub-organization">
            <div class="row">
              <div class="col col-3 d-flex flex-column align-items-center">
                <div class="line-connect"> <div class="line"></div> </div>
                <OrganiPerson :data="honor" />
              </div>
              <div class="col col-3 d-flex flex-column align-items-center">
                <div class="line-connect"> <div class="line"></div> </div>
                <OrganiPerson :data="rrpp" />
              </div>
              <div class="col col-3 d-flex flex-column align-items-center">
                <div class="line-connect"> <div class="line"></div> </div>
                <OrganiPerson :data="secretary" />
              </div>
              <div class="col col-3 d-flex flex-column align-items-center">
                <div class="line-connect"> <div class="line"></div> </div>
                <OrganiPerson :data="afiliation" />
              </div>
            </div>
          </div>
        </div>

        <div class="col col-12 d-block d-lg-none">
          <div class="d-flex flex-column align-items-center">
            <OrganiPerson :data="president" />
            <div class="line-connect"> <div class="line"></div> </div>
            <OrganiPerson :data="vice" />
            <div class="line-connect"> <div class="line"></div> </div>
          </div>
          <div class="main-line-min"></div>
          <div class="sub-organization">
            <div class="row">
              <div class="col col-12 col-sm-6 d-flex flex-column align-items-center mb-4">
                <OrganiPerson :data="honor" />
              </div>
              <div class="col col-12 col-sm-6 d-flex flex-column align-items-center mb-4">
                <OrganiPerson :data="rrpp" />
              </div>
              <div class="col col-12 col-sm-6 d-flex flex-column align-items-center mb-4">
                <OrganiPerson :data="secretary" />
              </div>
              <div class="col col-12 col-sm-6 d-flex flex-column align-items-center mb-4">
                <OrganiPerson :data="afiliation" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import OrganiPerson from "@/components/general/OrganiPerson.vue";
export default {
  name: "OrganigramaComp",
  components: {
    OrganiPerson,
  },
  data() {
    return {
      president: {
        name: "José Carlos González Macías",
        position: "PRESIDENTE",
        img: require("@/assets/org/Presidente.png"),
      },
      vice: {
        name: "Zulma Ramírez",
        position: "VICEPRESIDENTE",
        img: require("@/assets/org/Vicepresidente.png"),
      },
      honor: {
        name: "Carolina Almaguer",
        position: "HONOR Y JUSTICIA",
        img: require("@/assets/org/Honor.png"),
      },
      rrpp: {
        name: "Víctor Ramírez",
        position: "RELACIONES PÚBLICAS",
        img: require("@/assets/org/RRPP.png"),
      },
      secretary: {
        name: "Johans Ortega",
        position: "SECRETARIO GENERAL",
        img: require("@/assets/org/Secretario.png"),
      },
      afiliation: {
        name: "Lic. Karen de Anda",
        position: "AFILIACIONES",
        img: require("@/assets/org/Afiliacion.png"),
      }
    };
  },
};
</script>

<style lang="scss" scoped>
.organigrama-comp {
  position: relative;
  background-color: var(--color-gray);
  height: fit-content;
  min-height: 500px;
  width: 100%;
  padding: 2rem 2rem 4rem;

  .line-connect {
    width: 100%;
    max-width: 300px;
    height: 24px;
    position: relative;
    .line {
      width: 2px;
      height: 100%;
      background-color: #c3c3c3;
      position: absolute;
      left: calc(98px / 2);
    }
  }
  .main-line {
    width: 100%;
    padding-left: calc(98px / 2);
    padding-right: calc(25% - 138px / 2);
    .line {
      width: 100%;
      height: 2px;
      background-color: #c3c3c3;
    }
  }

  .main-line-min {
    width: 100%;
    height: 24px;
    border-top-right-radius: 8px;
    border-top-left-radius: 8px;
    border-top: 2px solid #c3c3c3;
    border-left: 2px solid #c3c3c3;
    border-right: 2px solid #c3c3c3;
  }
}
</style>
