var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"noticias-view"},[_c('NavBar',{on:{"toggleMenu":_vm.toggleMenu}}),_vm._m(0),_c('div',{staticClass:"container"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12 col-xl-7 mb-5"},[_c('div',{staticClass:"row px-sm-5 mb-4"},[_c('div',{staticClass:"col-12",attrs:{"data-aos":"fade-in","data-aos-duration":"1000","data-aos-delay":"200"}},[_c('video-player',{ref:"videoPlayer",staticClass:"video-player-box",attrs:{"options":_vm.playerOptions,"playsinline":true}})],1)]),_c('div',{staticClass:"system-grid px-sm-5"},_vm._l((_vm.noticias),function(item){return _c('div',{key:item.id,staticClass:"card-noticia",class:[ 
              item.id == 0 ? 'small-card-left' : '',
              item.id == 1 ? 'large-card-right' : '',
              item.id == 2 ? 'large-card-left' : '',
              item.id == 3 ? 'small-card-right' : '',         
            ]},[_c('div',{staticClass:"card-noticia-body"},[_c('div',[_c('h3',{staticClass:"ff-grandis tw-medium ts-h3",class:{ 'tc-white' : item.id == 3}},[_vm._v(_vm._s(item.titulo))]),_c('p',{staticClass:"tw-regular parrafo",class:{ 'tc-white' : item.id == 3}},[_vm._v(_vm._s(item.descripcion))])]),_c('a',{staticClass:"button-noticia",class:{ 'tc-white' : item.id == 3},attrs:{"href":item.link,"target":"_blank"}},[_vm._v("Leer más "),_c('i',{staticClass:"bi bi-arrow-right"})])]),(item.imagen)?_c('img',{staticClass:"card-noticia-img",attrs:{"src":item.imagen,"alt":"Img"}}):_vm._e()])}),0)]),_vm._m(1)])])],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"container pt-5 px-5 px-md-0",attrs:{"data-aos":"fade-in","data-aos-duration":"1000"}},[_c('div',{staticClass:"row mb-5"},[_c('p',{staticClass:"mb-3 text-uppercase tc-text-lighter"},[_vm._v("/ Conoce ASPI")]),_c('h1',{staticClass:"ff-grandis tc-text-light"},[_vm._v("Noticias")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-12 col-xl-5"},[_c('iframe',{staticStyle:{"border":"none","overflow":"hidden"},attrs:{"src":"https://www.facebook.com/plugins/page.php?href=https%3A%2F%2Fwww.facebook.com%2Faspi.lapaz.bcs%2F%3Flocale%3Des_LA&tabs=timeline&width=500&height=700&small_header=false&adapt_container_width=true&hide_cover=false&show_facepile=false&appId","height":"700","width":"500","scrolling":"no","frameborder":"0","allowfullscreen":"true","small_header":"false","adapt_container_width":"true","hide_cover":"false","show_facepile":"false","allow":"autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share"}})])
}]

export { render, staticRenderFns }