import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
  },
  getters: {
  },
  mutations: {
    SET_STATE_VALUE( state, payload ) {
      for( let key in payload ){
        if( !payload.hasOwnProperty( key ) ) continue;
        state[key] = payload[key];
      }
    },
  },
  actions: {
  },
  modules: {
  }
})
