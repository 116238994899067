import { render, staticRenderFns } from "./BuzonQuejasForm.vue?vue&type=template&id=61aa569c&scoped=true&"
import script from "./BuzonQuejasForm.vue?vue&type=script&lang=js&"
export * from "./BuzonQuejasForm.vue?vue&type=script&lang=js&"
import style0 from "./BuzonQuejasForm.vue?vue&type=style&index=0&id=61aa569c&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "61aa569c",
  null
  
)

export default component.exports