<template>
  <div class="container" data-aos="fade-down" data-aos-duration="1500">
    <header class="w-100 px-4 py-3">
      <nav class="d-flex justify-content-between align-items-center">
        <router-link to="/">
          <img class="logotype" src="@/assets/logo-aspi-full-white.svg" />
        </router-link>
        <div class="d-none d-lg-flex gap-4">
          <router-link to="/">
            <span
              class="ff-grandis text-uppercase tc-white"
              :class="{ 'tw-black': currentPath === '/' }"
              >Inicio</span
            >
          </router-link>
        <!--   <router-link to="/bolsa">
            <span
              class="ff-grandis text-uppercase tc-white"
              :class="{ 'tw-black': currentPath === '/bolsa' }"
              >Bolsa</span
            >
          </router-link> -->
          <router-link to="/nosotros">
            <span
              class="ff-grandis text-uppercase tc-white"
              :class="{ 'tw-black': currentPath === '/nosotros' }"
              >Nosotros</span
            >
          </router-link>
          <router-link to="/asociados">
            <span
              class="ff-grandis text-uppercase tc-white"
              :class="{ 'tw-black': currentPath === '/asociados' }"
              >Asociados</span
            >
          </router-link>
          <router-link to="/afiliacion">
            <span
              class="ff-grandis text-uppercase tc-white"
              :class="{ 'tw-black': currentPath === '/afiliacion' }"
              >Afiliación</span
            >
          </router-link>
          <router-link to="/noticias">
            <span
              class="ff-grandis text-uppercase tc-white"
              :class="{ 'tw-black': currentPath === '/noticias' }"
              >Noticias</span
            >
          </router-link>
          <router-link to="/contacto">
            <span
              class="ff-grandis text-uppercase tc-white"
              :class="{ 'tw-black': currentPath === '/contacto' }"
              >Contáctanos</span
            >
          </router-link>
        </div>
        <div class="d-flex d-lg-none">
          <button class="btn button-menu" @click="toggleMenu">
            <i class="bi bi-list"></i>
          </button>
        </div>
      </nav>
    </header>
  </div>
</template>

<script>
export default {
  name: "HomeNavBar",
  computed: {
    currentPath() {
      return this.$route.path;
    }
  },
  methods: {
    toggleMenu() {
      this.$emit("toggleMenu");
    }
  }
};
</script>

<style lang="scss" scoped>
.logotype {
  height: 78px;
  transition: var(--transition-fast);
  &:hover { opacity: 0.7; }
  &:active { transform: scale(0.98); }
}
.button-menu {
  background: rgba($color: #ffffff, $alpha: 0.1);
  color: var(--color-white);
  font-size: var(--h3-font-size);
}
</style>