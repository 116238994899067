<template>
  <div id="mapContainer" class="basemap"></div>
</template>

<script>
import mapboxgl from 'mapbox-gl';
export default {
  data() {
    return {
      accessToken: 'pk.eyJ1IjoiZW5yaWtlMTU5IiwiYSI6ImNrdml6M2JjNWJmdWUyd3Q5ZWJpa2FibG8ifQ.ktz70lqjO4x7nt13Qh5kiw',
    };
  },
  mounted() {
    mapboxgl.accessToken = this.accessToken;

    const map = new mapboxgl.Map({
      container: "mapContainer",
      style: "mapbox://styles/mapbox/light-v11",
      center: [-110.309114, 24.151817],
      zoom: 14,
    });

    const marker = new mapboxgl.Marker()
      .setLngLat([-110.309114, 24.151817])
      .addTo(map);
  },
};
</script>

<style lang="scss" scoped>
.basemap {
  width: 100%;
  height: 200px;
  border-radius: 1rem;
}
</style>
