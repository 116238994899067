<template>
  <div class="drawer-menu" :class="{ active : showDrawer }">
    <div class="drawer-menu__header">
      <div class="drawer-menu__header__title">
        <h2 class="ff-grandis">Menu</h2>
      </div>
      <div class="drawer-menu__header__close">
        <button @click="closeDrawer">
          <i class="bi bi-x-square"></i>
        </button>
      </div>
    </div>
    <div class="drawer-menu__body">
      <div class="drawer-menu__body__item">
        <router-link to="/">
          <span>Inicio</span>
        </router-link>
      </div>

      <div class="drawer-menu__body__item">
        <router-link to="/nosotros">
          <span>Nosotros</span>
        </router-link>
      </div>

      <div class="drawer-menu__body__item">
        <router-link to="/asociados">
          <span>Asociados</span>
        </router-link>
      </div>

      <div class="drawer-menu__body__item">
        <router-link to="/afiliacion">
          <span>Afiliación</span>
        </router-link>
      </div>

      <div class="drawer-menu__body__item">
        <router-link to="/noticias">
          <span>Noticias</span>
        </router-link>
      </div>

      <div class="drawer-menu__body__item">
        <router-link to="/contacto">
          <span>Contáctanos</span>
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "DrawerMenu",
  props: {
    showDrawer: {
      type: Boolean,
      default: true,
    },
  },
  watch: {
    showDrawer(newValue, oldValue) {
      if (newValue !== oldValue && newValue === true) {
        // disable overflow y on every element
        document.body.style.overflowY = "hidden";
      } else {
        // enable overflow y on every element
        document.body.style.overflowY = "auto";
      }
    },
  },
  beforeDestroy() {
    // enable overflow y on every element
    document.body.style.overflowY = "auto";
  },
  methods: {
    closeDrawer() {
      this.$emit("closeDrawer");
    },
  },
};
</script>

<style lang="scss" scoped>
.drawer-menu {
  width: 100%;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 999;
  background: rgba($color: #ffffff, $alpha: 0.7);
  backdrop-filter: blur( 24px );
  -webkit-backdrop-filter: blur( 24px );
  transform: translateX(100%);
  transition: transform 0.3s ease-in-out;
  &.active {
    transform: translateX(0);
  }

  &__header {
    width: 100%;
    height: 5rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 1rem;
    border-bottom: 1px solid #ccc;
    &__title {
      h2 {
        font-size: 1.5rem;
        font-weight: 600;
      }
    }
    &__close {
      button {
        background-color: transparent;
        border: none;
        cursor: pointer;
        i {
          font-size: 1.5rem;
          color: #000;
        }
      }
    }
  }

  &__body {
    width: 100%;
    height: calc(100% - 5rem);
    padding: 1rem;
    overflow-y: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    &__item {
      width: fit-content;
      height: 3rem;
      display: flex;
      align-items: center;
      a {
        font-family: var(--grandis-font);
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        text-decoration: none;
        color: #000;
        span {
          font-size: var(--h2-font-size);
        }
      }
    }
  }
}
</style>