<template>
  <div class="nosotros-view">
    <NavBar @toggleMenu="toggleMenu" />
    <!-- <header class="main-header" data-aos="zoom-in">
      <div class="container p-relative">
        <h1 class="ff-grandis tc-text-light">Nosotros</h1>
        <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Aperiam et nostrum ea deserunt.</p>
      </div>
    </header> -->
    <div class="container pt-5 px-5 px-md-0">
      <div class="row mb-5">
        <div class="col" data-aos="fade-down" data-aos-duration="1000">
          <p class="mb-3 text-uppercase tc-text-lighter">
            / Comprometidos con la excelencia y la ética
          </p>
          <h1 class="ff-grandis tc-text-light">Nosotros</h1>
        </div>
      </div>
      <div class="row">
        <div class="col-12 col-lg-6 mb-5">
          <p class="ts-h3 mb-3" data-aos="fade-right" data-aos-duration="1000">
            Nosotros somos la Asociación de Profesionales Inmobiliarios (ASPI)
            de Baja California Sur, una organización comprometida con la
            promoción de la excelencia, la ética y la profesionalización en el
            mercado inmobiliario de la región.
          </p>
          <p
            class="ts-h3 mb-5"
            data-aos="fade-right"
            data-aos-duration="1000"
            data-aos-delay="100"
          >
            Nos enfocamos en fomentar una comunidad de profesionales
            inmobiliarios colaborativa e innovadora, que esté comprometida en
            ofrecer el mejor servicio a sus clientes y promover el desarrollo
            económico y social de Baja California Sur.
          </p>

          <div class="row mb-3">
            <div class="col-12 col-md-12 mb-3">
              <div
                class="info-stats"
                data-aos="fade-right"
                data-aos-duration="1000"
                data-aos-delay="200"
              >
                <div class="d-flex align-items-center gap-1">
                  <i class="bi bi-bullseye tc-primary ts-h2"></i>
                  <h4 class="ff-grandis">Misión</h4>
                </div>
                <p class="tc-text-dark tw-medium ts-h3 ps-3">
                  Nuestra misión es profesionalizar el mercado inmobiliario de
                  Baja California Sur capacitando y educando a profesionales
                  inmobiliarios.
                </p>
              </div>
            </div>
            <div class="col-12 col-md-12">
              <div
                class="info-stats"
                data-aos="fade-right"
                data-aos-duration="1000"
                data-aos-delay="300"
              >
                <div class="d-flex align-items-center gap-1">
                  <i class="bi bi-eye tc-secondary ts-h2"></i>
                  <h4 class="ff-grandis">Visión</h4>
                </div>
                <p class="tc-text-dark tw-medium ts-h3 ps-3">
                  Nuestra visión es ser la asociación líder en la promoción de
                  la excelencia, la ética y la profesionalización en el mercado
                  inmobiliario de Baja California Sur.
                </p>
              </div>
            </div>
          </div>
        </div>
        <div class="col-12 col-lg-6">
          <div class="resultados-container br-bigger">
            <img
              class="resultados-img"
              src="@/assets/img/nosotros.jpeg"
              alt=""
              data-aos="fade-left"
              data-aos-duration="1000"
            />
            <div class="row mb-lg-3">
              <div class="spacer-height"></div>
              <!-- <div class="col col-12 pt-3 pt-md-0" data-aos="fade-right" data-aos-duration="1000" >
                <h2 class="ff-grandis tw-medium ts-big">Nuestra mision</h2>
                <p class="ts-h3 mb-0">
                  Nuestra misión es profesionalizar el mercado inmobiliario de
                  Baja California Sur
                </p>
              </div> -->
            </div>
            <div class="row">
              <div
                class="col col-12 col-md-4 px-4 mb-5 mb-md-2"
                data-aos="fade-right"
                data-aos-duration="800"
                data-aos-delay="50"
              >
                <div class="w-100 bg-primary mb-3" style="height: 3px"></div>
                <h3 class="ff-grandis ts-big tc-text-light tw-medium">64</h3>
                <p class="ff-grandis ts-normal tc-text-light">Afiliados</p>
              </div>
              <div
                class="col col-12 col-md-4 px-4 mb-5 mb-md-2"
                data-aos="fade-right"
                data-aos-duration="800"
                data-aos-delay="200"
              >
                <div class="w-100 bg-secondary mb-3" style="height: 3px"></div>
                <h3 class="ff-grandis ts-big tc-text-light tw-medium">10</h3>
                <p class="ff-grandis ts-normal tc-text-light">Años expertise</p>
              </div>
              <div
                class="col col-12 col-md-4 px-4 mb-5 mb-md-2"
                data-aos="fade-right"
                data-aos-duration="800"
                data-aos-delay="350"
              >
                <div class="w-100 bg-primary mb-3" style="height: 3px"></div>
                <h3 class="ff-grandis ts-big tc-text-light tw-medium">5</h3>
                <p class="ff-grandis ts-normal tc-text-light">Ciudades</p>
              </div>
            </div>
            <div class="row pt-4">
              <div
                class="col col-12 mb-3"
                data-aos="fade-down"
                data-aos-duration="800"
              >
                <h4 class="text-center ff-grandis tc-text-dark mb-2">
                  Baja California Sur
                </h4>
                <div
                  style="
                    width: 90%;
                    border-bottom: 1px solid #a5a5a5;
                    margin: 0 auto;
                  "
                ></div>
              </div>
            </div>
            <div class="places" data-aos="fade-up" data-aos-duration="800">
              <div class="place-card card-1">
                <h1>La Cabos</h1>
                <p>10 miembros</p>
              </div>
              <div class="place-card card-2">
                <h1>La Paz</h1>
                <p>10 miembros</p>
              </div>
              <div class="place-card card-3">
                <h1>Loreto</h1>
                <p>10 miembros</p>
              </div>
              <div class="place-card card-4">
                <h1>Comondú</h1>
                <p>10 miembros</p>
              </div>
              <div class="place-card card-5">
                <h1>Mulegé</h1>
                <p>10 miembros</p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="row py-5">
        <div class="col-12 col-md-6 mb-5">
          <img
            src="@/assets/img/nosotros_2.jpg"
            alt="Equipo"
            class="ts-h3 mb-5 br-bigger center-img"
            data-aos="fade-right"
            data-aos-duration="1000"
          />
        </div>
        <div class="col-12 col-md-6">
          <p class="ts-h3 mb-5" data-aos="fade-left" data-aos-duration="1000">
            Como asociación, ofrecemos una variedad de programas de formación y
            capacitación, así como eventos y oportunidades de networking, para
            nuestros miembros. Buscamos estar a la vanguardia de la tecnología y
            las mejores prácticas en la industria, para ofrecer las herramientas
            y recursos más innovadores y eficaces a nuestros miembros.
          </p>
          <p
            class="ts-h3 mb-5"
            data-aos="fade-left"
            data-aos-duration="1000"
            data-aos-delay="100"
          >
            En ASPI, creemos que el éxito del mercado inmobiliario depende de la
            profesionalización y la colaboración de sus actores, y estamos
            comprometidos en liderar este cambio en la región. Si comparte
            nuestros valores y objetivos, lo invitamos a unirse a nuestra
            comunidad y ser parte de la transformación del mercado inmobiliario
            de Baja California Sur.
          </p>
        </div>
      </div>

      <div class="row py-5">
        <div class="col col-12 col-md-8 order-1 order-md-0">
          <h1 class="ff-grandis tc-text-light">
            Certificamos agentes inmobiliarios
          </h1>
          <p class="tc-text-dark ts-h2 tw-bold">
            Asesoría en comercialización de bienes inmuebles
          </p>
          <p class="tc-text-lighter tw-medium"> Inscrito en el Registro Nacional de Estándares de Competencia. Clave: EC0110.02 </p>
          <div class="images-expedidos mb-4">
            <img src="@/assets/gobierno.png" alt="Gobierno de México">
            <img src="@/assets/educacion.jpg" alt="Educacion">
            <img src="@/assets/conocer-logo.png" alt="Red Conocer">
          </div>
          <p class="tc-text ts-h3 mb-4">
            Las certificaciones en asesoría de comercialización de inmuebles son
            una herramienta valiosa para destacar en el mercado. Estas
            demuestran conocimientos avanzados en áreas clave como
            comercialización estratégica, evaluación de propiedades, gestión de
            clientes y negociación efectiva. Obtener certificaciones muestra
            compromiso con la excelencia profesional y garantiza a los clientes
            trabajar con un profesional capacitado en comercialización
            inmobiliaria.
          </p>
          <p class="tc-text ts-h3">
            ¿Buscas certificarte? <router-link to="/contacto" class="tc-primary tw-bold">Contáctanos aquí</router-link>
          </p>
        </div>

        <div class="col col-12 col-md-4 order-0 order-md-1">
          <lottie-player
            src="./static/certificate.json"
            background="transparent"
            class="lottie-img"
            speed="1"
            style="width: 100%"
            loop
            autoplay
          ></lottie-player>
        </div>
      </div>
    </div>

    <div class="container pt-5">
      <div
        class="w-100 d-flex justify-content-center align-items-center flex-column"
      >
        <h2 class="ff-grandis tw-medium ts-h1 text-center">
          Únete a nuestro equipo
        </h2>
        <p class="tc-text-dark ts-h3 text-center mb-3">
          Si quieres ser parte de nuestra comunidad, ve a nuestra sección de
          afiliación.
        </p>
        <router-link
          to="/afiliacion"
          class="btn btn-primary button-compromiso mb-3"
        >
          Quiero afiliarme
          <i class="bi bi-arrow-right"></i>
        </router-link>
        <div
          class="w-100"
          style="border-bottom: 1px solid #a5a5a5; margin: 0 auto"
        ></div>
      </div>
    </div>
  </div>
</template>

<script>
import NavBar from "@/components/general/NavBar.vue";
export default {
  components: {
    NavBar,
  },
  metaInfo: {
    title: "ASPI",
    titleTemplate: "%s | Nosotros",
  },
  methods: {
    toggleMenu() {
      this.$emit("toggleMenu");
    },
  },
};
</script>

<style lang="scss" scoped>
.main-header {
  background-image: url("@/assets/img/nosotros.jpeg");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  height: fit-content;
  padding: 120px 0 48px;
  position: relative;

  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
  }
}
.center-img {
  object-fit: cover;
  object-position: center;
  width: 100%;
  height: 100%;
  max-height: 360px;
}

.button-compromiso {
  background-color: transparent;
  color: var(--color-primary);
  border-radius: 2rem;
  border: 2px solid var(--color-primary);
  width: fit-content;
  margin: 0 auto;

  &:hover {
    background-color: var(--color-primary);
    color: var(--color-white);
  }
}
.info-stats {
  border-left: 2px solid var(--color-gray);
  padding-left: 1rem;
}
.resultados-container {
  position: relative;
  background-color: var(--color-gray);
  height: fit-content;
  min-height: 500px;
  width: 100%;
  padding: 2rem 3rem 2rem;

  .resultados-img {
    position: absolute;
    top: -2rem;
    right: -6rem;
    width: 100%;
    height: 220px;
    object-fit: cover;
    object-position: center;
    border-radius: 1rem;
  }

  .spacer-height {
    width: 100%;
    height: 180px;
  }

  .places {
    display: grid;
    // give me a template column repeat 5 times with minmax of 200px and 1fr to fit 5 cards in a row
    grid-template-columns: repeat(auto-fit, minmax(100px, 1fr));
    grid-gap: 0.5rem;

    .place-card {
      height: 160px;
      background-color: var(--color-primary);
      border-radius: 0.6rem;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      background-size: cover;
      background-position: center;
      background-repeat: no-repeat;
      position: relative;
      transition: all 0.3s ease-in-out;
      cursor: default;

      &::before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        border-radius: 0.6rem;
        background-color: rgba(0, 0, 0, 0.5);
        transition: inherit;
      }

      &:hover {
        // give zoom effect to the background image
        &::before {
          background-color: rgba(0, 0, 0, 0.3);
        }
        h1 {
          transform: translateY(-10px);
        }
        p {
          opacity: 1;
          transform: translateY(-5px);
        }
      }

      h1 {
        position: relative;
        font-size: 1.2rem;
        color: var(--color-white);
        transition: inherit;
      }

      p {
        position: relative;
        font-size: 0.8rem;
        color: var(--color-white);
        transition: inherit;
        opacity: 0;
      }
    }
    .card-1 {
      background-image: url("@/assets/img/loscabos_card.jpeg");
    }
    .card-2 {
      background-image: url("@/assets/img/lapaz_card.jpg");
    }
    .card-3 {
      background-image: url("@/assets/img/loreto_card.jpg");
    }
    .card-4 {
      background-image: url("@/assets/img/comondu_card.webp");
    }
    .card-5 {
      background-image: url("@/assets/img/mulege_card.jpg");
    }
  }
}

.images-expedidos {
  display: flex;
  gap: 1rem;
  img {
    width: 100%;
    max-width: 98px;
    object-fit: contain;
  }
}
@media only screen and (max-width: 960px) {
  .resultados-container {
    padding: 10rem 2rem 8rem;

    .spacer-height {
      height: 0px;
    }
    .resultados-img {
      top: -2rem;
      right: -6rem;
      width: 100%;
      height: 160px;
    }
  }

  .lottie-img {
    max-width: 128px;
    margin: 0 auto;
  }
}
</style>
