import Vue from 'vue'
import VueRouter from 'vue-router'
import Meta from 'vue-meta'

// LAYOUTS
import DefaultLayout from '@/layouts/DefaultLayout.vue'

// VIEWS
import HomeView from '@/views/HomeView.vue'
import NosotrosView from '@/views/NosotrosView.vue'
import AsociadosView from '@/views/AsociadosView.vue'
import AfiliacionView from '@/views/AfiliacionView.vue'
import NoticiasView from '@/views/NoticiasView.vue'
import ContactoView from '@/views/ContactoView.vue'
import InProgressView from '@/views/InProgressView.vue'

Vue.use(VueRouter)
Vue.use(Meta)

const routes = [
  {
    path: '/',
    component: DefaultLayout,
    children: [
      {
        path: '',
        name: 'home',
        component: InProgressView
      },
      // {
      //   path: '/nosotros',
      //   name: 'nosotros',
      //   component: InProgressView,
      // },
      // {
      //   path: '/asociados',
      //   name: 'asociados',
      //   component: AsociadosView,
      // },
      // {
      //   path: '/afiliacion',
      //   name: 'afiliacion',
      //   component: AfiliacionView,
      // },
      // {
      //   path: '/noticias',
      //   name: 'noticias',
      //   component: NoticiasView,
      // },
      // {
      //   path: '/contacto',
      //   name: 'contacto',
      //   component: ContactoView,
      // }
    ]
  },
 
  // {
  //   path: '/about',
  //   name: 'about',
  //   component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
  // }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

// each route go to scroll top
router.beforeEach((to, from, next) => {
  window.scrollTo(0, 0)
  next()
})

export default router
