<template>
  <div id="footer_comp">
    <div class="container px-0 px-md-5">
      <div class="d-flex justify-content-between align-items-end align-items-md-center py-5">
        <div class="d-flex flex-lg-row flex-column gap-4">
          <router-link to="/">
            <span
              class="ff-grandis text-uppercase tc-text"
              :class="{ 'tw-black': currentPath === '/' }"
              >Inicio</span
            >
          </router-link>
          <!-- <router-link to="/bolsa">
            <span
              class="ff-grandis text-uppercase tc-text"
              :class="{ 'tw-black': currentPath === '/bolsa' }"
              >Bolsa</span
            >
          </router-link> -->
          <router-link to="/nosotros">
            <span
              class="ff-grandis text-uppercase tc-text"
              :class="{ 'tw-black': currentPath === '/nosotros' }"
              >Nosotros</span
            >
          </router-link>
          <router-link to="/asociados">
            <span
              class="ff-grandis text-uppercase tc-text"
              :class="{ 'tw-black': currentPath === '/asociados' }"
              >Asociados</span
            >
          </router-link>
          <router-link to="/afiliacion">
            <span
              class="ff-grandis text-uppercase tc-text"
              :class="{ 'tw-black': currentPath === '/afiliacion' }"
              >Afiliación</span
            >
          </router-link>
          <router-link to="/noticias">
            <span
              class="ff-grandis text-uppercase tc-text"
              :class="{ 'tw-black': currentPath === '/noticias' }"
              >Noticias</span
            >
          </router-link>
          <router-link to="/contacto">
            <span
              class="ff-grandis text-uppercase tc-text"
              :class="{ 'tw-black': currentPath === '/contacto' }"
              >Contáctanos</span
            >
          </router-link>
        </div>

        <div class="d-flex gap-2">
          <a href="https://www.facebook.com/aspi.lapaz.bcs" target="_blank" class="btn-icon"><i class="bi bi-facebook"></i></a>
          <!-- <button class="btn-icon"><i class="bi bi-instagram"></i></button> -->
          <a href="#" class="btn-icon"><i class="bi bi-whatsapp"></i></a>
        </div>
      </div>

      <div class="d-block d-sm-flex justify-content-between align-items-center py-4" style="border-top: 1px solid var(--color-text-light);">
        <span>/ @2023, Asociación Sudcaliforniana de Profesionales Inmobiliarios A.C.</span> <br> <br>
        <span>Desarrollado en La Paz, Baja California Sur, México</span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'FooterComp',
  computed: {
    currentPath() {
      return this.$route.path;
    },
  },
}
</script>

<style lang="scss" scoped>
#footer_comp {
  width: 100%;
  height: fit-content;
  padding: 2rem;

  .btn-icon {
    width: 2rem;
    height: 2rem;
    border-radius: 50%;
    background-color: var(--color-white);
    display: grid;
    place-items: center;
    transition: var(--transition-fast);

    &:hover {
      background-color: var(--color-gray);
    }
  }
}
</style>