<template>
  <div class="buzon-quejas-form">
    <p class="mb-3">/ LLENA EL SIGUIENTE FORMULARIO DE SUGERENCIAS Y QUEJAS</p>
    <form @submit.prevent="sendEmail()">
      <input
        type="text"
        class="form-control"
        v-model="form.name"
        placeholder="Nombre"
      />
      <textarea
        class="form-control"
        v-model="form.message"
        placeholder="Mensaje de queja o sugerencia"
      />

      <div class="row">
        <div class="col col-12">
          <p class="tc-text-dark ts-small">
            {{ error }}
          </p>
        </div>
      </div>

      <div class="row">
        <div class="col col-12 col-md-4 mb-2">
          <button type="submit" class="btn btn-primary button-form">
            Enviar
            <i class="bi bi-arrow-right"></i>
          </button>
        </div>
        <div class="col col-12 col-md-8">
          <p class="tc-text-dark ts-small">
            *Al enviar este formulario nosotros revisaremos sus 
            comentarios y los tomaremos en cuenta para la mejora 
            de la asociación.
          </p>
        </div>
      </div>
    </form>
  </div>
</template>

<script>
import emailjs from "@emailjs/browser";
import { emptyString } from "@/utils/emptyString";
import { emailValid } from "@/utils/emailValid";
export default {
  name: 'BuzonQuejasForm',
  data() {
    return {
      form: {
        name: '',
        message: '',
      },
      error: '',
    }
  },
  methods: {
    validarForm() {
      if (emptyString(this.form.name))
        return "El nombre es requerido";
      if (emptyString(this.form.message))
        return "Se requiere de un mensaje para enviar";
      if (this.form.message.length < 10)
        return "El mensaje debe tener al menos 10 caracteres";
      return true;
    },
    sendEmail() {
      if (this.validarForm() == true) {
        this.error = "Enviando...";
        emailjs
          .send(
            "aspi_gmail_service",
            "aspi_justicia_template",
            {
              name: this.form.name,
              message: this.form.message,
            },
            "qMYNrezbauUPILqnP"
          )
          .then(
            (result) => {
              this.error = "Mensaje enviado correctamente";
              console.log("SUCCESS!", result.status, result.text);
              this.form.name = "";
              this.form.message = "";
            },
            (error) => {
              this.error = "Algo salio mal, intenta de nuevo";
              console.log("FAILED...", error);
            }
          );
      } else {
        this.error = this.validarForm();
      }
    },
    emailValid(email) {
      const re = /\S+@\S+\.\S+/
      return re.test(email)
    },
  },
}

</script>

<style lang="scss" scoped>
.buzon-quejas-form {
  padding: 1rem;
}
.form-control {
  border-radius: 0;
  border: none;
  background: none;
  border-bottom: 1px solid var(--color-text);
  padding: 0.5rem;
  margin-bottom: 2rem;
  &:focus {
    outline: none;
    box-shadow: none;
    border-bottom: 1px solid var(--color-primary);
    &::placeholder {
      color: var(--color-primary);
    }
  }
}

.button-form {
  background-color: var(--color-primary);
  border: none;
  color: white;
  border-radius: 2rem;
  padding: 0.4rem 2rem;
}
</style>