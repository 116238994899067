<template>
  <div class="container px-2 px-md-5" style="margin-top: 98px; margin-bottom: 48px">
    <div class="row px-3 px-sm-0">
      <div class="col-12">
        <h6
          class="tw-regular mb-5"
          data-aos="fade-right"
          data-aos-duration="800"
        >
          / DESCUBRE POR QUÉ ASOCIARTE
        </h6>
        <h2
          class="ff-grandis ts-big tw-medium mb-5"
          data-aos="fade-right"
          data-aos-duration="800"
        >
          ¿Por qué asociarse a ASPI?
        </h2>
      </div>
    </div>
    <div class="row px-5">
      <div class="col-12 col-md-7 col-lg-6 mb-5 order-1 order-md-0">
        <p
          class="tc-text-light mb-3"
          data-aos="fade-right"
          data-aos-duration="800"
        >
          Afiliarse a ASPI como profesional inmobiliario en Baja California Sur
          es una decisión estratégica que puede impulsar su carrera y su
          negocio. Al afiliarse, tendrá acceso a una comunidad de profesionales
          inmobiliarios comprometidos con la excelencia y la ética en su
          trabajo.
        </p>

        <p
          class="tc-text-light mb-3"
          data-aos="fade-right"
          data-aos-duration="800"
          data-aos-delay="100"
        >
          Podrá participar en eventos, programas de formación y capacitación.
          Además, ser parte de ASPI significa tener una voz en la promoción de
          prácticas justas y transparentes en el mercado inmobiliario de la
          región, y contribuir al desarrollo y crecimiento económico y social de
          Baja California Sur.
        </p>

        <p
          class="tc-text-light mb-3"
          data-aos="fade-right"
          data-aos-duration="800"
          data-aos-delay="200"
        >
          En resumen, afiliarse a ASPI es una inversión en su carrera y su
          negocio, y una oportunidad para ser parte de una comunidad de
          profesionales inmobiliarios líderes en el mercado.
        </p>

        <div class="row">
          <!-- <div class="col-6 col-md-12 col-lg-6" data-aos="fade-right" data-aos-duration="800" data-aos-delay="200">
            <p>/ AFILIADOS Y COLABORADORES</p>
            <div style="height: 80px"></div>
            <ICountUp
              class="ts-max tc-primary ff-grandis tw-regular line-h-05"
              :delay="delay"
              :endVal="endVal"
              :options="options"
              @ready="onReady"
            />
          </div> -->
          <div class="col-12 pt-3">
            <router-link to="/afiliacion" class="btn btn-primary button-compromiso" data-aos="fade-right" data-aos-duration="800">
              Quiero afiliarme
              <i class="bi bi-arrow-right"></i>
            </router-link>
          </div>
        </div>
      </div>
      <div class="col-12 col-md-5 col-lg-6 mb-5">
        <img
          class="why-us-image"
          src="@/assets/img/porque_nosotros.jpeg"
          alt=""
          data-aos="fade-left"
          data-aos-duration="800"
        />
      </div>
      <!-- <div class="col col-12 col-md-3" v-for="(item, index) in afiliados" :key="`afiliados-${index}`">
        <div class="afiliado" v-if="item.show">
          <div class="afiliado-logo">
            <img src="https://picsum.photos/seed/picsum/60/60" alt="Logo afiliado">
          </div>
          <div class="afiliado-nombre">
            <span>{{ item.nombre }}</span>
          </div>
        </div>
      </div> -->
    </div>
  </div>
</template>

<script>
import ICountUp from "vue-countup-v2";

export default {
  components: {
    ICountUp,
  },
  name: "IntegrantesHomeComp",
  data() {
    return {
      counterInstance: null,
      delay: 1000,
      endVal: 63,
      options: {
        useEasing: true,
        useGrouping: true,
        separator: ",",
        decimal: ".",
        prefix: "",
        suffix: "+",
      },
    };
  },
  methods: {
    onReady: function (instance, CountUp) {
      this.counterInstance = instance;
      instance.update(instance.endVal);
    },
  },
};
</script>

<style lang="scss" scoped>
.afiliado {
  display: flex;
  justify-content: center;
  gap: 1rem;
  background-color: var(--color-white);
  border-radius: 1rem;
  padding: 1rem;
  margin-bottom: 1.5rem;
  box-shadow: 0 3px 10px 0 rgba(0, 0, 0, 0.05);

  .afiliado-logo {
    width: 100%;
    max-width: 100px;

    img {
      width: 100%;
      object-fit: cover;
      border-radius: 1rem;
    }
  }

  .afiliado-nombre {
    font-family: var(--grandis-font);
    font-size: 1.2rem;
    font-weight: 500;
  }
}

.button-compromiso {
  background-color: transparent;
  color: var(--color-primary);
  border-radius: 2rem;
  border: 2px solid var(--color-primary);
  width: fit-content;

  &:hover {
    background-color: var(--color-primary);
    color: var(--color-white);
  }
}

.why-us-image {
  width: 100%;
  height: 360px;
  object-fit: cover;
  object-position: center;
  border-radius: 1rem;
}
</style>
