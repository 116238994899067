import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import AOS from 'aos'
import VueMeta from 'vue-meta'
import VueSplide from '@splidejs/vue-splide';
import VueVideoPlayer from 'vue-video-player'
import { init } from '@emailjs/browser';
init("djnH4ROkZDJfS3CCZkmvB");

import 'aos/dist/aos.css'
import '@splidejs/splide/dist/css/splide.min.css'
import 'mapbox-gl/dist/mapbox-gl.css'
import 'video.js/dist/video-js.css'

Vue.config.productionTip = false

new Vue({
  router,
  store,
  VueMeta,
  VueSplide,
  VueVideoPlayer,
  mounted() {
    AOS.init()
  },
  render: h => h(App)
}).$mount('#app')
