<template>
  <div class="organi-person">
    <div class="avatar-person">
      <img :src="data.img" alt="Avatar Image">
    </div>
    <div class="info-person d-flex flex-column justify-content-center">
      <h3 class="ff-grandis ts-h3 tw-medium tc-primary">{{ data.name }}</h3>
      <p class="ff-grandis ts-small tc-text-dark tw-bold">{{ data.position }}</p>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    data: {
      type: Object,
      required: true,
    },
  },
}
</script>

<style lang="scss" scoped>
.organi-person {
  display: flex;
  width: 100%;
  max-width: 300px;
  gap: 1rem;

  .avatar-person {
    width: 98px;
    height: 98px;
    min-width: 98px;
    min-height: 98px;
    border-radius: 50%;
    overflow: hidden;
    border: 3px solid transparent;
    //padding: 2px;
    background-color: transparent;
    box-shadow: var(--bs-dark);
    transition: var(--transition-fast);

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  &:hover {
    .avatar-person {
      border-color: var(--color-secondary);
    }
  }
}
</style>