var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"container py-5"},[_vm._m(0),_c('div',{staticClass:"w-100"}),_c('div',{staticClass:"system-grid px-sm-5"},_vm._l((_vm.noticias),function(item){return _c('div',{key:item.id,staticClass:"card-noticia",class:[ 
        item.id == 0 ? 'small-card-left' : '',
        item.id == 1 ? 'large-card-right' : '',
        item.id == 2 ? 'large-card-left' : '',
        item.id == 3 ? 'small-card-right' : '',         
      ],attrs:{"data-aos":"fade-up","data-aos-duration":"1000","data-aos-delay":item.id * 100}},[_vm._v(" > "),_c('div',{staticClass:"card-noticia-body"},[_c('div',[_c('h3',{staticClass:"ff-grandis tw-medium ts-h3",class:{ 'tc-white' : item.id == 3}},[_vm._v(_vm._s(item.titulo))]),_c('p',{staticClass:"tw-regular parrafo",class:{ 'tc-white' : item.id == 3}},[_vm._v(_vm._s(item.descripcion))])]),_c('a',{staticClass:"button-noticia",class:{ 'tc-white' : item.id == 3},attrs:{"href":item.link,"target":"_blank"}},[_vm._v("Leer más "),_c('i',{staticClass:"bi bi-arrow-right"})])]),(item.imagen)?_c('img',{staticClass:"card-noticia-img",attrs:{"src":item.imagen,"alt":"Img"}}):_vm._e()])}),0),_c('div',{staticClass:"row px-sm-5 py-5"},[_c('div',{staticClass:"col-0 col-md-2"}),_c('div',{staticClass:"col-12 col-md-8",attrs:{"data-aos":"fade-up","data-aos-duration":"1000","data-aos-delay":"500"}},[_c('video-player',{ref:"videoPlayer",staticClass:"video-player-box",attrs:{"options":_vm.playerOptions,"playsinline":true}})],1),_c('div',{staticClass:"col-0 col-md-2"})]),_vm._m(1)])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"row text-center mb-5"},[_c('h2',{staticClass:"ff-grandis tw-medium"},[_vm._v(" Entérate de lo último en el sector inmobiliario ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"row pt-5 pb-3",staticStyle:{"border-bottom":"1px solid var(--color-gray)"}},[_c('div',{staticClass:"col-12 text-center"},[_c('a',{staticClass:"button-ver-todas",attrs:{"href":"https://www.facebook.com/aspi.lapaz.bcs/?locale=es_LA","target":"_blank"}},[_vm._v("Ver todas las noticias "),_c('i',{staticClass:"bi bi-arrow-right"})])])])
}]

export { render, staticRenderFns }